import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="alert alert-info">
    <span className="material-icons">get_app</span> Login to <strong><a href="https://themestore.papathemes.com/bigcommerce" target="_blank">PapaThemes Store</a></strong> to download all these child themes for free when you've already purchased Supermarket theme.
    </div>
    <div className="alert alert-warning">
    <span className="material-icons">warning</span> This free child theme has limited techical support. We'll try to answer your questions about this theme, but we're not committed to answer all and fix all bugs of this theme.
    </div>
    <h1 {...{
      "id": "sima-beauty-theme"
    }}>{`Sima Beauty Theme`}</h1>
    <h2 {...{
      "id": "sections-manager"
    }}>{`Sections Manager`}</h2>
    <p>{`You can select the sections displayed on the homepage (maximum 11 sections) in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Sections`}</strong>{`.`}</p>
    <p><img alt="Homepage Sections Manager" src={require("../img/homepage-beauty-sections-manager.png")} /></p>
    <p><strong parentName="p">{`List of available sections keys:`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`beauty_topbanner`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`sima_beauty_salon`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`our_portfolio`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`appointment`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`catalogue`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`products_by_category_sorting_tabs`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`blog_recent`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`reviews_slider_beauty`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`creative_team`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`support_info`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`form_social`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "beauty-top-banner-section"
    }}>{`Beauty Top Banner Section`}</h2>
    <p><img alt="Beauty Top Banner Section" src={require("../img/sections/beauty-top-banner-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Furniture Featured Categories`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/beauty-top-banner-section-code.png")} /></p>
    <h2 {...{
      "id": "beauty-sima-salon-section"
    }}>{`Beauty Sima Salon Section`}</h2>
    <p><img alt="Beauty Sima Salon Section" src={require("../img/sections/beauty-sima-salon-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Furniture Featured Categories`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/beauty-sima-salon-section-code.png")} /></p>
    <h2 {...{
      "id": "beauty-our-portfolio-section"
    }}>{`Beauty Our Portfolio Section`}</h2>
    <p><img alt="Beauty Our Portfolio Section" src={require("../img/sections/beauty-our-portfolio-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Furniture Featured Categories`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/beauty-our-portfolio-section-code.png")} /></p>
    <h2 {...{
      "id": "beauty-appointment-section"
    }}>{`Beauty Appointment Section`}</h2>
    <p><img alt="Beauty Appointment Section" src={require("../img/sections/beauty-appointment-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Furniture Featured Categories`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/beauty-appointment-section-code.png")} /></p>
    <h2 {...{
      "id": "beauty-catalogue-section"
    }}>{`Beauty Catalogue Section`}</h2>
    <p><img alt="Beauty Catalogue Section" src={require("../img/sections/beauty-catalogue-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Furniture Featured Categories`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/beauty-catalogue-section-code.png")} /></p>
    <h2 {...{
      "id": "beauty-products-by-category-sorting-tabs-section"
    }}>{`Beauty Products By Category Sorting Tabs Section`}</h2>
    <p><img alt="Beauty Banner Sale Section" src={require("../img/sections/beauty-products-by-category-sorting-tabs-section.png")} /></p>
    <p>{`This section will show products organized categories. You can choose number of categories to display products. Categories are sorted by the order specified in `}<strong parentName="p">{`Product Categories`}</strong>{` in admin manager.`}</p>
    <p>{`To configure this section, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Products by Category with Sorting Tabs`}</strong>{`:`}</p>
    <p><img alt="banner content html source editor" src={require("../img/banner-products-by-category-sorting-tabs-source-editor.png")} /></p>
    <h4 {...{
      "id": "optimize-for-execution-time"
    }}>{`OPTIMIZE FOR EXECUTION TIME`}</h4>
    <p>{`By default, all products will be loaded and displayed when opening the page. At a result, it takes a longer execution time that can affect your site's Page Speed score. To avoid this, you can select `}<strong parentName="p">{`Only load when scrolling to the viewport`}</strong>{` option, the products won't loaded until user scrolls down to this area.`}</p>
    <h4 {...{
      "id": "specify-which-categories-to-display"
    }}>{`SPECIFY WHICH CATEGORIES TO DISPLAY`}</h4>
    <p>{`By default, the product categories displayed are the top categories in the order in which they are sorted on the main navigation. You can limit number of categories to display by setting Number of Categories option.`}</p>
    <p>{`You can manually specify which categories should be displayed by setting the categories ID to Category ID box, separated by comma. For example: 200,221,254,275. Check instruction How to find the category ID. Note that Number of Categories will be ignored if you choose to display categories manually.`}</p>
    <h2 {...{
      "id": "beauty-recent-posts-section"
    }}>{`Beauty Recent Posts Section`}</h2>
    <p><img alt="Beauty Recent Posts Section" src={require("../img/sections/beauty-recent-posts-section.png")} /></p>
    <p>{`To show Recent Posts section on `}<strong parentName="p">{`Home Page`}</strong>{`, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > set `}<strong parentName="p">{`Recent blog posts`}</strong>{` = number of posts to show up.`}</p>
    <h2 {...{
      "id": "beauty-review-slider-section"
    }}>{`Beauty Review Slider Section`}</h2>
    <p><img alt="Beauty Review Slider Section" src={require("../img/sections/beauty-review-slider-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Furniture Featured Categories`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/beauty-review-slider-section-code.png")} /></p>
    <h2 {...{
      "id": "beauty-creative-team-section"
    }}>{`Beauty Creative Team Section`}</h2>
    <p><img alt="Beauty Creative Team Section" src={require("../img/sections/beauty-creative-team-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Furniture Featured Categories`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/beauty-creative-team-section-code.png")} /></p>
    <h2 {...{
      "id": "beauty-support-info-section"
    }}>{`Beauty Support Info Section`}</h2>
    <p><img alt="Beauty Support Info Section" src={require("../img/sections/furniture-support-info-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Furniture Live Help`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/furniture-support-info-section-code.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      